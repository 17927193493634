import axios from "axios";
import router from "@/router";
import Vue from "vue";
const baseApiUrl = "http://localhost:5000/api"

const instance = axios.create({
  baseURL: baseApiUrl,
  withCredentials: true,
  timeout: 15000,
});

let isFetchingAuth = false; 

instance.interceptors.request.use(async (config) => {
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error.response) {
      console.error("Network Error:", error);
      return Promise.reject(error);
    }

    const { status } = error.response;

    if (status === 401 && !isFetchingAuth) {
      isFetchingAuth = true;
      try {
        const authResponse = await axios.get(baseApiUrl + "/authentication/validate", {
          withCredentials: true,
        });

        isFetchingAuth = false;

        if (authResponse.data.Authenticated) {
          console.log("Windows Authentication successful:", authResponse.data);
          return Promise.resolve(authResponse);
        } else {
          console.warn("Windows Authentication failed.");
          Vue.nextTick(() => {
            router.push("/").catch(() => {});
          });
        }
      } catch (authError) {
        isFetchingAuth = false;
        console.error("Windows Authentication request failed:", authError);
        Vue.nextTick(() => {
          router.push("/").catch(() => {});
        });
      }
    }

    if (status === 403) {
      console.warn("Access Denied: User is authenticated but lacks permission.");
      Vue.nextTick(() => {
        router.push("/").catch(() => {});
      });
    }

    return Promise.reject(error);
  }
);

export default instance;

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import vClickOutside from 'v-click-outside';
import './plugins/style.css'; 
import store from "./store/auth"; 

Vue.directive('click-outside', vClickOutside);
Vue.config.productionTip = false
Vue.prototype.$apiUrl = 'http://localhost:48489/'
Vue.prototype.$keyvaultsecret = "";

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
